// @flow
import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { Store } from 'Store'
import { fetchCampaign } from 'Actions'

import Expired from 'pages/Expired'
import Landing from 'pages/Landing'

import Step1 from 'pages/Step1'
import Welcome from 'pages/Welcome'
import Success from 'pages/Success'

import PromoService from 'components/PromoService'
import PromoHouse from 'components/PromoHouse'
import Footer from 'components/Footer'
import TopBar from 'components/TopBar'

import CampaignNotFound from './components/CampaignNotFound'
import Favicon from 'components/Favicon'
import OnRouterChange from 'components/OnRouterChange'

const IS_CAMPAIGN_URL = /^\/.+$/.test(window.location.pathname)

const AppRoute = ({ component: Component, layout: Layout = MainLayout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
            <OnRouterChange />
        </Layout>
    )}/>
)

const MainLayout = props => {
    const { state } = React.useContext(Store)

    return (
        <>
            <TopBar dealershipName={state.campaign.client.name} locale={state.locale} date={state.campaign.expiryDate}/>
            {props.children}
            <Footer/>
        </>
    )
}

const NoLayout = (props) => (<>{props.children}</>)

export default function App () {
    const { state, dispatch } = React.useContext(Store)
    const [loading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        (async () => {
            if (IS_CAMPAIGN_URL && state.campaign === null && state.errors.campaignNotFound !== true) {
                setIsLoading(true)
                await fetchCampaign(dispatch)
                setIsLoading(false)
            }

            if (state.campaign) {
                document.title = `${state.campaign.name}`
            }
        })()
    }, [dispatch, state.campaign, state.errors.campaignNotFound])

    React.useEffect(() => {
        if (loading) return document.body.classList.add('loading')
        document.body.classList.remove('loading')
    }, [loading])

    if (state.errors.campaignNotFound) {
        return <CampaignNotFound/>
    }

    if (IS_CAMPAIGN_URL && state.campaign === null) {
        return <h1>Loading</h1>
    }

    if (state.campaign && state.campaign.expired) {
        return <Expired />
    }

    return (
        <Suspense fallback={<div>loading...</div>}>
            <BrowserRouter>
                <Switch>
                    <AppRoute exact path='/' component={Welcome} layout={NoLayout}/>
                    <AppRoute exact path='/:url/step-1' component={Step1}/>
                    <AppRoute exact path='/:url/success' component={Success}/>
                    <AppRoute exact path='/:url/promo-voucher/service' layout={NoLayout} component={PromoService}/>
                    <AppRoute exact path='/:url/promo-voucher/house' layout={NoLayout} component={PromoHouse}/>
                    <AppRoute exact path='/:url' component={Landing}/>
                </Switch>
            </BrowserRouter>
            {state.campaign && (
                <Favicon />
            )}
        </Suspense>
    )
}
