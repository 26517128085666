// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MainLogo from 'components/MainLogo'
import { Store } from 'Store'

import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        firstName: ?string,
        lastName: ?string,
        email: ?string,
        mobilePhone: ?string,
    }
}

function Step1 (props: Props) {
    const { state } = React.useContext(Store)
    const { theme } = props
    const { t } = useTranslation(transDomain)
    const firstName = `${state.lead.fields.firstName || ''}`

    const makes = React.useMemo(() => (
        state.campaign.models.map(model => model.make).filter((value, index, self) => self.indexOf(value) === index)
    ), [state.campaign.models])

    const models = React.useMemo(() => (
        state.campaign.models.filter(model => model.make === props.values.newVehicleMake)
    ), [props.values.newVehicleMake, state.campaign.models])

    const transactionTypeOptions = React.useMemo(() => {
        const options = []

        if (state.campaign.usedModels && state.campaign.usedModels.length > 0) options.push('Pre-owned purchase')
        if (state.campaign.models.length > 0) options.push('New vehicle purchase')
        if (state.campaign.allowVehicleSellOnly) options.push('Sell my current vehicle only')

        return options
    }, [state.campaign.usedModels, state.campaign.models, state.campaign.allowVehicleSellOnly])

    const defaultModel = React.useMemo(() => {
        return state.campaign.models.find(model => model.name === props.values.newVehicleModel && model.year === props.values.newVehicleModelYear)
            || state.campaign.usedModels.find(usedModel => usedModel.name === props.values.newVehicleModel && usedModel.year === props.values.newVehicleModelYear)
    }, [state.campaign.models, state.campaign.usedModels, props.values.newVehicleModel, props.values.newVehicleModelYear])

    const [transactionType, setTransactionType] = React.useState(() => {
        // eslint-disable-next-line
        switch (true) {
            case state.campaign.models.includes(defaultModel):
                return 'New vehicle purchase'
            case state.campaign.usedModels.includes(defaultModel):
                return 'Pre-owned purchase'
            case state.lead.fields.sellCurrentVehicleOnly === true:
                return 'Sell my current vehicle only'
        }
    })

    const handleTransactionTypeHandle = React.useCallback(type => {
        setTransactionType(type)
        let sellCurrentVehicleOnly = type === 'Sell my current vehicle only'

        props.handleFieldsChange(
            [sellCurrentVehicleOnly, null, null, null],
            ['sellCurrentVehicleOnly', 'newVehicleModelYear', 'newVehicleModel', 'newVehicleMake']
        )
        // eslint-disable-next-line
    }, [])

    return (
        <div style={{ '--primary-font': theme.fontFamily }} className={styles.leadGenFormContainer}>
            <div className={styles.sideBar} style={{ backgroundColor: theme.sidebarBackgroundColor }}>
                <MainLogo width='250px' height='106px' viewBox='0 0 275 120'/>

                <h4 className={styles.title} style={{
                    color: theme.sidebarTitleColor
                }}>
                    {t('sidebar.title', {
                        dealershipName: state.campaign.client.name
                    })}
                </h4>

                <p className={styles.sidebarText} style={{
                    color: theme.sidebarTextColor
                }}>
                    {t('sidebar.description')}
                </p>

                <div className={styles.signature}>
                    <strong>{state.campaign.contactInfo.name},</strong>
                    <br />
                    <span>{state.campaign.contactInfo.position}, {state.campaign.client.name}</span>
                </div>
            </div>
            <div className={styles.rightSide}>
                <div className={styles.heading}>
                    <h2 style={{ color: theme.titleColor }}>
                        {t(props.theme.headingFieldKey, {
                            firstName
                        })}
                    </h2>
                    <div className={styles.description} style={{ color: theme.descriptionColor }}>
                        {t('description')}
                    </div>
                </div>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={0.5}>
                            <Form.Field
                                // error={props.errors.firstName || null}
                                defaultValue={props.values.firstName || null}
                                onChange={value => props.handleFieldChange('firstName', value)}
                                type='text'
                                label={t('label.firstName')}
                                placeholder={t('placeholder.firstName')}
                            />
                        </Form.RowColumn>
                        <Form.RowColumn size={0.5}>
                            <Form.Field
                                // error={props.errors.lastName || null}
                                defaultValue={props.values.lastName || null}
                                onChange={value => props.handleFieldChange('lastName', value)}
                                type='text'
                                label={t('label.lastName')}
                                placeholder={t('placeholder.lastName')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={0.5}>
                            <Form.Field
                                // error={props.errors.email || null}
                                defaultValue={props.values.email || null}
                                onChange={value =>
                                    props.handleFieldChange('email', value)
                                }
                                type='email'
                                label={t('label.emailAddress')}
                                placeholder={t('placeholder.emailAddress')}
                            />
                        </Form.RowColumn>
                        <Form.RowColumn size={0.5}>
                            <Form.Field
                                // error={props.errors.eveningPhone || null}
                                defaultValue={props.values.mobilePhone || null}
                                onChange={value => props.handleFieldChange('mobilePhone', value)}
                                type='phone'
                                label={t('label.mobilePhone')}
                                placeholder={t('placeholder.mobilePhone')}
                                required={true}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <span className={styles.separator} style={{
                    backgroundColor: theme.separatorColor
                }} />

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={1}>
                            <Form.Field
                                size={1}
                                // error={props.errors.currentVehicleMake || null}
                                defaultValue={props.values.currentVehicleMake || null}
                                onChange={value =>
                                    props.handleFieldChange('currentVehicleMake', value)
                                }
                                type='text'
                                label={t('label.vehicleMake')}
                                placeholder={t('placeholder.vehicleMake')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={0.5}>
                            <Form.Field
                              // error={props.errors.currentVehicleYear || null}
                                defaultValue={props.values.currentVehicleYear || null}
                                onChange={value =>
                                    props.handleFieldChange('currentVehicleYear', parseInt(value, 10))
                                }
                                type='text'
                                label={t('label.vehicleYear')}
                                placeholder={t('placeholder.vehicleYear')}
                            />
                        </Form.RowColumn>
                        <Form.RowColumn size={0.5}>
                            <Form.Field
                              // error={props.errors.currentVehicleModel || null}
                                defaultValue={props.values.currentVehicleModel || null}
                                onChange={value =>
                                    props.handleFieldChange('currentVehicleModel', value)
                                }
                                type='text'
                                label={t('label.vehicleModel')}
                                placeholder={t('placeholder.vehicleModel')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <span className={styles.separator} style={{
                    backgroundColor: theme.separatorColor
                }} />

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={1}>
                            <Form.Field label={'What type of transaction are you most interested in?'}>
                                <Form.Dropdown
                                    // error={props.errors.newVehicleMake || null}
                                    options={transactionTypeOptions}
                                    trans={t}
                                    required={true}
                                    placeholder={'Select transaction type of interest'}
                                    onChange={handleTransactionTypeHandle}
                                    defaultValue={transactionType}
                                />
                            </Form.Field>
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                {(transactionType === 'Pre-owned purchase') && (
                    <Form.Row>
                        <Form.RowColumns>
                            <Form.RowColumn size={1}>
                                <Form.Field label={'What type of vehicle are you looking for?'}>
                                    <Form.ModelPickerDropdown
                                        placeholder={'Select vehicle type of interest'}
                                        models={state.campaign.usedModels}
                                        required
                                        defaultModel={defaultModel}
                                        onChange={value => {
                                            props.handleFieldsChange(
                                              [value?.year, value?.name, value?.make, false],
                                              ['newVehicleModelYear', 'newVehicleModel', 'newVehicleMake', 'sellCurrentVehicleOnly']
                                            )
                                        }}
                                    />
                                </Form.Field>
                            </Form.RowColumn>
                        </Form.RowColumns>
                    </Form.Row>
                )}

                {(transactionType === 'New vehicle purchase') && (
                    <>
                        {makes.length > 1 && (
                            <Form.Row>
                                <Form.Field
                                  label={t('field.newVehicleMake.label')}
                                >
                                    <Form.Dropdown
                                      // error={props.errors.newVehicleMake || null}
                                      placeholder={t('field.newVehicleMake.placeholder')}
                                      required
                                      options={makes}
                                      onChange={value => {
                                          props.handleFieldsChange(
                                              [value, '', '', false],
                                              ['newVehicleMake', 'newVehicleModelYear', 'newVehicleModel', 'sellCurrentVehicleOnly']
                                          )
                                      }}
                                      defaultValue={props.values.newVehicleMake}
                                    />
                                </Form.Field>
                            </Form.Row>
                        )}

                        <Form.Row>
                            <Form.Field label={'What model of vehicle are you looking for?'}>
                                <Form.ModelPickerDropdown
                                    placeholder={'Select vehicle type of interest'}
                                    models={models}
                                    required
                                    defaultModel={defaultModel}
                                    onChange={value => {
                                        props.handleFieldsChange(
                                          [value?.year, value?.name, false],
                                          ['newVehicleModelYear', 'newVehicleModel', 'sellCurrentVehicleOnly']
                                        )
                                    }}
                                />
                            </Form.Field>
                        </Form.Row>
                  </>
                )}

                <div className={styles.submitContainer}>
                    <Form.Submit
                        isLoading={props.isLoading}
                        label={t('label.continueToStep2')}
                        style={{
                            background: theme.background,
                            color: theme.color
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(withForm()((Step1)))
