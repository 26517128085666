// @flow
import '@shift-marketing/event-websites-chatbot/dist/main.css'
import * as React from 'react'

import { Currency } from 'i18n/NumberFormatter'
import { useTranslation, Trans } from 'react-i18next'

import { Store } from 'Store'
import useTimeout from 'hook/useTimeout'
import withTheme from 'hoc/withTheme'
import Voucher from 'components/Voucher'
import {
    svgToImage,
    // downloadBlob
} from '@shift-marketing/event-websites-helper'
import { sendAppointmentDate, sendClientMessage, sendAutoresponder } from 'Actions'
import { AppointmentWidgetWrapper as AppointmentWidget } from '@shift-marketing/event-websites-chatbot'

import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function HeaderWithVoucher (props) {
    const { theme } = props
    const { state, dispatch } = React.useContext(Store)
    const { t } = useTranslation(transDomain)
    const [appointmentWidget, setAppointmentWidget] = React.useState(false)
    const [vouchermMounted, setVoucherMounted] = React.useState(null)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    const firstName = state.lead.fields.firstName

    React.useEffect(() => {
        if (!state.autoresponderShouldBeSent) return

        if (vouchermMounted) {
            svgToImage(vouchermMounted, async blob => {
                // await downloadBlob(blob, 'test.png')
                await sendAutoresponder(dispatch, `${state.campaign.incentives.voucher.name}.png`, blob)
            })
        }
    }, [state.campaign.incentives.voucher.name, state.autoresponderShouldBeSent, vouchermMounted, dispatch])

    const voucherRefCallback = React.useCallback(voucher => {
        if (voucher) {
            setVoucherMounted(voucher)
        }
    }, [])

    useTimeout(() => {
        if (state.campaign.hasOwnProperty('incentives') && state.campaign.incentives.hasOwnProperty('onlineBooking') && !state.campaign.expired) {
            let localTimezone
            try {
                localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            } catch {
                localTimezone = state.campaign.timezone
            }
            setAppointmentWidget(
                <AppointmentWidget
                    onlineBooking={state.campaign.incentives.onlineBooking}
                    timezone={localTimezone}
                    lead={state.lead}
                    clientName={state.campaign.client.name}
                    expiryDate={state.campaign.expiryDate}
                    colors={theme.colors}
                    actions={{
                        sendAppointmentDate,
                        sendClientMessage
                    }}
                    currencyFormatter={Currency(state.currency, state.locale)}
                />
            )
        }
    }, 5000)

    return (
        <>
            {appointmentWidget}

            <div style={{ '--primary-font': props.theme.primaryFont }}>
                <span className={styles.congratsMessage} style={{
                    color: props.theme.congratsMessageColor
                }}>
                    <Trans t={t} i18nKey={'congratsMessage'} values={{
                        voucher: voucher.name,
                        type: voucher.type,
                        firstName: firstName,
                        value: voucher.value > 0 ? currencyFormatter.format(voucher.value) : 0
                    }}>
                        Congratulations, {firstName}!
                        <br />
                        You have secured your offer
                    </Trans>
                </span>

                <div className={styles.serviceVoucher}>
                    <Voucher
                        width="100%"
                        ref={voucherRefCallback}
                    />
                </div>
            </div>
        </>
    )
}

export default withTheme(supportedThemes)(HeaderWithVoucher)
