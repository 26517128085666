// @flow
import React from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Store } from 'Store'
import Button from 'components/Button'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function SuccessInfoArea (props) {
    const { t } = props
    const { state } = React.useContext(Store)
    const { email } = state.lead.fields

    const inventoryLink = state.lead.promoMaterial?.website || state.campaign.client.website

    const handleInventoryRedirect = React.useCallback(() => {
        window.open(inventoryLink, '_blank')
    }, [inventoryLink])

    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: props.theme.backgroundColor,
                '--primary-font': props.theme.primaryFont
            }}>
            <div>
                <div className={styles.footerArea}>
                    <span className={styles.information}>
                        <h5 className={styles.title}>
                            {t('title')}
                        </h5>
                        <Trans t={t} values={{ email }} i18nKey={'information'}>
                            An email has been sent to: <u style={{ color: props.theme.primaryColor }}>{email}</u>
                            <br />
                            with your voucher and information on next steps.
                        </Trans>
                    </span>
                    <span className={styles.buttonContainer}>
                        {inventoryLink && (
                            <Button style={{
                                backgroundColor: props.theme.primaryColor,
                                color: props.theme.buttonTextColor
                            }} size='large' color='primary' onClick={handleInventoryRedirect}>
                                View our current inventory
                            </Button>
                        )}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(withTranslation(transDomain)(withRouter((SuccessInfoArea))))
