// @flow
import * as React from 'react'

import SuccessInfoArea from 'components/SuccessInfoArea'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import HeaderWithVoucher from 'components/HeaderWithVoucher'
import styles from './styles.module.scss'
import Layout from '../Layout'

function SuccessInfo (props) {
    return (
        <Layout>
            <div className={styles.formContainer} style={{
                '--primary-font': props.theme.primaryFont
            }}>
                <HeaderWithVoucher/>
                <SuccessInfoArea/>
            </div>
        </Layout>
    )
}

export default withTheme(supportedThemes)(SuccessInfo)
