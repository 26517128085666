// @flow
import * as React from 'react'
import cn from 'classnames'
import { Redirect } from 'react-router-dom'
import { Trans, withTranslation } from 'react-i18next'
import queryString from 'query-string'
import { Store } from 'Store'
import { fetchLead } from 'Actions'

import withTheme from 'hoc/withTheme'
import supportedThemes, { Theme } from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

type Props = {
    theme: Theme
}

function RegistrationForm (props: Props) {
    const { t, theme } = props

    const loader = { '--color-loader': `url(${theme.loader})` }

    const [code, setCode] = React.useState(queryString.parse(window.location.hash)['code'] || '')
    const [isLoading, setIsLoading] = React.useState(false)
    const [invalidCode, setInvalidCode] = React.useState(false)

    const { state, dispatch } = React.useContext(Store)

    if (state.lead) {
        return <Redirect to={`${window.location.pathname}/step-1${window.location.search}`} push />
    }

    return (
        <form onSubmit={async e => {
            e.preventDefault()
            setIsLoading(true)

            try {
                await fetchLead(dispatch, code)
            } catch (err) {
                console.error(err)
                setInvalidCode(true)
                setIsLoading(false)
            }
        }}>
            <div className={styles.registrationFormContainer} style={{
                '--placeholder-color': theme.placeholderColor,
                '--primary-color': theme.primaryColor
            }}>
                <div className={styles.formInputcontainer}>
                    <input
                        type="text"
                        placeholder={t('placeholder')}
                        name="code"
                        required
                        style={{
                            backgroundColor: theme.inputBackgroundColor,
                            color: theme.inputTextColor
                        }}
                        className={styles.input}
                        value={code}
                        onChange={e => setCode(e.target.value)}
                    />

                    <button
                        className={cn(styles.submit, { [styles.isLoading]: isLoading })}
                        type="submit"
                        style={{
                            backgroundColor: theme.buttonBackgroundColor,
                            color: theme.buttonTextColor,
                            ...loader
                        }}
                        disabled={isLoading}
                        >
                        <span>{t('continue')}</span>
                    </button>
                </div>

                <label htmlFor='code' style={{ color: theme.labelTextColor }}>
                    <Trans i18nKey='label'>Enter your <strong> promo code </strong> to claim your voucher</Trans>
                </label>
            </div>

            {invalidCode && (
                <div className={styles.invalidLabel}>
                    <Trans i18nKey='wrongCode'>You have entered an invalid code</Trans>
                </div>
            )}
        </form>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegistrationForm))
