// @flow

import * as React from 'react'
import AbsoluteTime from 'components/AbsoluteTime'
import { wrapText } from '../Form/Helpers'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'

type Props = {
    viewBox?: string | number,
    width?: string | number,
    height?: string | number
}

const Voucher = React.forwardRef(function (props: Props, ref) {
    const { state } = React.useContext(Store)
    const { campaign, locale, currency } = state
    const currencyFormatter = Currency(currency, locale)

    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        126
    )

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox={props.viewBox || '0 0 684 293'}
            height={props.height}
            width={props.width || '100%'}
            ref={ref}
        >
            <defs>
                <rect id="a" x={0} y={0} width={678} height={287} rx={5} />
            </defs>
            <g fill="#FFFFFF" fillRule="evenodd">
                <g transform="translate(3 3)">
                    <rect
                        stroke={props.theme.secondaryColor}
                        strokeWidth={3}
                        x={-1.5}
                        y={-1.5}
                        width={681}
                        height={290}
                        rx={5}
                    />
                    <path fill="#F7F7F7" d="M448 0h230v178H448z" />
                    <g>
                        <path
                            fill={props.theme.primaryLogoColor}
                            d="m596.007 81.964-6.033 12.084-1.837-12.084h-6.677l4.346 18.367-1.933 11.044h6.43l1.794-10.364 11.08-19.047zM612.556 87.075c.073 0 .147.001.221.005 1.859.07 2.791 1.442 2.853 4.182 0 .718-.078 1.76-.234 3.095l-.69 4.87c-.37 2.556-1.022 4.412-1.939 5.517-.898 1.083-1.995 1.6-3.416 1.536-1.913-.083-2.873-1.376-2.934-3.963-.039-.713.02-1.722.174-2.995l.691-4.835c.372-2.613 1.03-4.548 1.957-5.752.858-1.116 1.945-1.66 3.317-1.66m.675-5.505c-2.107-.047-4.05.44-5.743 1.427-1.69.987-3.119 2.51-4.249 4.528-1.12 2-1.883 4.322-2.269 6.903l-.731 4.784a16.786 16.786 0 0 0-.121 3.06c.134 2.923.978 5.246 2.507 6.903 1.537 1.668 3.64 2.54 6.25 2.595l.279.003c3.132 0 5.769-1.115 7.84-3.316 2.11-2.245 3.484-5.41 4.083-9.409l.652-4.401c.147-1.001.207-2.076.182-3.199-.11-3-.924-5.403-2.422-7.146-1.512-1.759-3.618-2.678-6.258-2.732M639.6 81.964l-3.384 20.049c-.214 1.576-.653 2.706-1.307 3.36-.648.647-1.53.943-2.71.907-1.649-.06-2.417-.862-2.418-2.509l.075-1.657 3.382-20.15h-6.368L623.465 102c-.34 2.937.238 5.314 1.716 7.065 1.48 1.754 3.676 2.67 6.528 2.725l.27.002c2.902 0 5.292-.847 7.109-2.519 1.866-1.718 3.024-4.136 3.439-7.178l3.422-20.13H639.6ZM499.385 68.462l-3.1 17.824-4.705-17.824h-6.09l-5.09 29.41h6.349l3.099-17.802 4.686 17.803h6.07l5.11-29.411zM504.326 97.873h16.71l.967-5.49h-10.382l1.206-6.944h8.762l.948-5.353h-8.784l1.067-6.115h10.322l.966-5.51h-16.691zM550.397 68.462l-4.91 16.208-.392-16.208h-5.348l-5.454 16.13.196-16.13h-6.25l.505 29.41h6.275l5.329-15.372.54 15.373h6.254l9.655-29.411z"
                        />
                        <path
                            fill={props.theme.secondaryLogoColor}
                            d="M526.108 111.594h13.484l1.35-8.791H473.13zM588.908 68.548c-.48 2.872-1.462 5.807-2.94 8.79h67.502l-48.672-8.79h-15.89ZM575.746 83.705c5.046-6.156 7.862-12.021 8.37-17.436.435-4.781-.65-8.802-3.225-11.95-2.603-3.182-6.261-4.855-10.88-4.973-5.265-.119-9.718 1.672-13.243 5.312-2.363 2.44-4.046 5.42-5.067 8.873h9.992c.483-1.06 1.056-2.003 1.743-2.793 1.605-1.848 3.608-2.712 6.12-2.644 1.345.063 2.39.55 3.194 1.487.858 1.001 1.375 2.41 1.535 4.162a15.555 15.555 0 0 1-1.41 7.766c-1.18 2.536-3.106 5.348-5.72 8.35l-21.141 22.907-1.372 8.828h35.316l1.397-8.791h-22.588l14.101-15.693 2.878-3.405Z"
                        />
                        <path
                            fill={props.theme.primaryLogoColor}
                            d="M588.04 122.758h-3.717l-.56 3.232h4.403l-.36 2.043h-6.798l2.11-12.19h6.79l-.36 2.05h-4.38l-.502 2.881h3.726zM597.121 124.659l3.366-8.816h2.738l-5.317 12.19h-2.503l-1.482-12.19h2.604zM612.842 122.758h-3.718l-.56 3.232h4.403l-.36 2.043h-6.798l2.11-12.19h6.79l-.36 2.05h-4.379l-.502 2.881h3.725zM625.766 128.033h-2.345l-2.126-8.08-1.407 8.08h-2.403l2.11-12.19h2.353l2.135 8.088 1.406-8.088h2.395zM640.607 117.894h-2.922l-1.758 10.14h-2.411l1.758-10.14h-2.88l.36-2.051h8.213z"
                        />
                    </g>
                </g>

                <text
                    fontFamily="proxima-nova"
                    fontSize={15}
                    fill="#000"
                    transform="translate(3 3)"
                    textAnchor='middle'
                >
                    <tspan x={'33%'} y={42}>
                        {state.lead.fields.firstName}, you've secured a
                    </tspan>
                </text>

                <text
                    fontFamily="proxima-nova"
                    fontSize={100}
                    fontWeight={900}
                    fill={props.theme.voucherValueColor}
                    transform="translate(172 3)"
                    textAnchor='middle'
                >
                    <tspan x={'8%'} y={123}>
                        {currencyFormatter.format(campaign.incentives.voucher.value)}
                    </tspan>
                </text>
                <text
                    fontFamily="proxima-nova"
                    fontSize={15}
                    fill="#000"
                    transform="translate(3 3)"
                    textAnchor='middle'
                >
                    <tspan x={'33%'} y={154}>
                        {campaign.incentives.voucher.name} {'with a successful transaction.'}
                    </tspan>
                </text>
                <text
                    fontFamily="proxima-nova"
                    fontSize={8.5}
                    letterSpacing={-0.2}
                    fill="#000"
                    text-anchor="middle"
                    transform="translate(32 228)"
                >

                    {terms.map((line, i) => (
                        <tspan key={i} x="45%" y={(12 * (i + 1))}>{line}</tspan>
                    ))}
                </text>
                <path fill={props.theme.secondaryColor} d="M3 181h678v40H3z" />
                <text
                    fontFamily="proxima-nova"
                    fontSize={11}
                    letterSpacing={-0.11}
                    fill="#FFF"
                    transform="translate(0 204)"
                    textAnchor='middle'
                >
                    <tspan x='50%'>
                        {'This offer is redeemable only at '}  {campaign.client.name}  {' Valid Until: '} <AbsoluteTime noContainer dateFormat='long' date={campaign.expiryDate} />
                    </tspan>
                </text>
            </g>
        </svg>
    )
})

export default withTheme(supportedThemes)(Voucher)
