// @flow
import * as React from 'react'
import { withTranslation } from 'react-i18next'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'
import supportedThemes, { Theme } from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = {
    theme: Theme
}

function Layout (props: Props) {
    return (
        <div
            className={styles.layout}
            style={{ '--primary-color': props.theme.primaryColor }}
        >
            <div className={styles.bgWrapper} style={{
                '--layout-bg': `url(${props.theme.background})`,
                '--layout-bg-mobile': `url(${props.theme.backgroundMobile})`,
            }}>
                {props.children}
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(withTranslation(transDomain)(Layout))
