// @flow
import * as React from 'react'
import RegistrationForm from 'components/RegistrationForm'
import MainLogo from 'components/MainLogo'

import { Currency } from 'i18n/NumberFormatter'

import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function RegisterForm (props) {
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher

    const isPreOwnedOnly = React.useMemo(() => {
        return state.campaign.models.length === 0 && state.campaign.usedModels.length > 0
    }, [state.campaign.models, state.campaign.usedModels])

    return (
        <div className={styles.registerForm} style={{
            '--primary-font': props.theme.primaryFont,
        }}>
            <MainLogo/>

            <div>
                <h3 className={styles.title} style={{ color: props.theme.primaryColor }}>
                    <Trans t={props.t} i18nKey="title" values={{
                        dealershipName: state.campaign.client.name
                    }}>
                        A rare opportunity from <br /> {state.campaign.client.name} for you!
                    </Trans>
                </h3>

                <span className={styles.subtitle} style={{
                    color: props.theme.secondaryColor,
                }}>
                    <Trans t={props.t} i18nKey="subtitle" values={{
                        voucher: voucher.name,
                        type: voucher.type,
                        value: voucher.value > 0 ? currencyFormatter.format(voucher.value) : 0
                    }}>
                        Move into one of our great {isPreOwnedOnly ? 'pre-owned vehicles and' : 'new or pre-owned vehicles and'}
                        <br />
                        receive preferred pricing as well as a
                        <strong> {currencyFormatter.format(voucher.value)} {voucher.type}</strong>.
                    </Trans>
                </span>
            </div>

            <RegistrationForm/>
        </div>
    )
}

export default withTheme(supportedThemes)(withTranslation(transDomain)((RegisterForm)))
