// @flow
import * as React from 'react'

import LeadGenForm from 'components/LeadGenForm/Step1'
import { Store } from 'Store'
import { updateLead } from 'Actions'

import { Redirect } from 'react-router-dom'

// import styles from './styles.module.scss'
import Layout from '../../components/Layout'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-1', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-1', 'success')}${props.location.search}`} push />
    }
    return (
        <Layout>
            <LeadGenForm
                onSubmit={async (data, done) => {
                    try {
                        let lead = {
                            fields: data,
                            isComplete: true
                        }
                        await updateLead(dispatch, state.code, lead)
                        setSubmitted(true)
                    } catch (err) {
                        alert('Oops something went wrong')
                        console.error(err)
                        done(err)
                    }
                }}
                initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
            />
        </Layout>
    )
}
