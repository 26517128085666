// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes.js'

import EventLogo from './EventLogo'

type Props = {
    width?: string | number,
    height?: string | number,
    viewBox?: string,
}

function MainLogo (props: Props) {
    return (
        <EventLogo
            height={props.height}
            width={props.width}
            primaryColor={props.theme.primaryColor}
            secondaryColor={props.theme.secondaryColor}
            ternaryColor={props.theme.ternaryColor}
            viewBox={props.viewBox}
        />
    )
}

export default withTheme(themes)(MainLogo)
