// @flow
import * as React from 'react'

import { Currency } from 'i18n/NumberFormatter'
import { useTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import { formatDateTime } from 'i18n/DateFormat'

import transDomain from './translations/index.translations'
import { Store } from 'Store'

import EventLogo from 'components/MainLogo/EventLogo'

type Props = {
    width: string, // eg. "541px"
    locale: string,
    campaign: { [string]: mixed },
}

PromoVoucher.defaultProps = {
    width: '541px'
}

function PromoVoucher (props: Props, ref) {
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const { campaign, locale, currency } = state
    const { theme } = props
    const currencyFormatter = Currency(currency, locale)
    const expiryDate = formatDateTime(campaign.expiryDate, 'long', null)

    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        180
    )

    return (
        <svg width={props.width} viewBox="0 0 541 216" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" ref={ref}>
            <defs>
                <linearGradient x1="50%" y1="20.5741808%" x2="50%" y2="100%" id="linearGradient-1">
                    <stop stopColor={theme.leftGradient.top} offset="0%"></stop>
                    <stop stopColor={theme.leftGradient.bottom} offset="100%"></stop>
                </linearGradient>
                <rect id="path-2" x="0" y="0" width="531" height="206" rx="4"></rect>
            </defs>
            <g>
                <rect id="Rectangle" fill={theme.secondary} x="0" y="0" width="541" height="216" rx="8"></rect>
                <g id="Rectangle" transform="translate(5.000000, 5.000000)">
                    <mask id="mask-3" fill="white">
                        <use xlinkHref="#path-2"></use>
                    </mask>
                    <use id="Mask" fill="url(#linearGradient-1)" xlinkHref="#path-2"></use>
                    <rect fill="#FFFFFF" mask="url(#mask-3)" x="310" y="-3" width="226" height="214"></rect>
                    <rect fill="#FFFFFF" mask="url(#mask-3)" x="-5" y="174" width="541" height="37"></rect>
                </g>
            </g>
            <g transform="translate(0.000000, 159.000000)">
                <g fill={theme.secondary}>
                    <rect x="0" y="0" width="541" height="23"></rect>
                </g>
                <g transform="translate(5.000000, 0.000000)" fontWeight="normal" letterSpacing="0" fontFamily={theme.fontFamily}>
                    <text x="41" y="15" textAnchor="middle" fontSize="10" fill='#EFF9FC'>
                        {t('visit')}
                    </text>
                    <rect fill="#EFF9FC" x="80" y="3" width="263" height="17" rx="2"></rect>
                    <text fontFamily={theme.fontFamily} letterSpacing="0" fontSize="8.5" fontWeight="normal" fill={theme.primary}>
                        <tspan textAnchor="middle" x="211.408472" y="14">www.{window.location.host}/{window.location.pathname.split('/')[1]}</tspan>
                    </text>
                    <text x="397" y="15" textAnchor="middle" fontSize="10" fill='#EFF9FC'>
                        {t('promo_code')}
                    </text>
                    <rect fill="#EFF9FC" x="450" y="3" width="74" height="17" rx="2"></rect>
                    <text fontFamily={theme.fontFamily} letterSpacing="0" fontSize="8.5" fontWeight="normal" fill={theme.primary}>
                        <tspan textAnchor="middle" x="488" y="14">{ props.promoCode }</tspan>
                    </text>
                    <text fontSize="5" fill={theme.textColor} textAnchor="start">
                        {terms.map((line, i) => (
                            <tspan x="10" y={(terms.length > 2 ? 32 : 34) + i * (terms.length > 2 ? 7 : 8)} key={i}>{line}</tspan>
                        ))}
                    </text>
                </g>
                <g transform="translate(493.000000, 31.000000)">
                    <image
                        x={theme.manufacturerLogoStyle.x}
                        y={theme.manufacturerLogoStyle.y}
                        width={theme.manufacturerLogoStyle.width}
                        height={theme.manufacturerLogoStyle.height}
                        xlinkHref={theme.manufacturerLogo}
                    />
                </g>
            </g>

            <g transform="translate(20.000000, 28.000000)" fontWeight="normal" fontFamily={theme.fontFamily} letterSpacing="0">
                {campaign.incentives.voucher.value > 0 ? (
                    <text fontSize="62" letterSpacing="-0.699999988" fill={theme.primary}>
                        <tspan textAnchor="middle" x="140" y="85" fontSize="78" letterSpacing="-2" fontWeight="900">
                            {currencyFormatter.format(campaign.incentives.voucher.value)}
                        </tspan>
                    </text>
                ) : (
                    <text
                        fill={theme.textColor}
                        fontFamily="'proxima-nova', 'sans-serif'"
                        fontSize="16"
                        fontWeight="800"
                    >
                        <tspan x="140" y="65" textAnchor="middle">
                            {campaign.incentives.voucher.name}
                        </tspan>
                    </text>
                )}

                <text fontSize="10.9" fill={theme.textColor}>
                    <tspan textAnchor="middle" x="140" y="0.66259766" fontFamily={theme.fontFamily} letterSpacing="0">
                        <Trans t={t} i18nKey="voucher.prefix.1" values={{ expiryDate }}>
                            On now until <tspan style={{ fontWeight: 'bold' }}>{expiryDate}</tspan>
                        </Trans>
                    </tspan>
                    <tspan textAnchor="middle" x="140" y="14.5" fontFamily={theme.fontFamily} letterSpacing="0">
                        {t('voucher.prefix.3', {
                            type: state.campaign.incentives.voucher.type,
                            name: state.campaign.incentives.voucher.name,
                            value: campaign.incentives.voucher.value,
                        })}
                    </tspan>
                </text>
                <text fontSize="10.9" fill={theme.textColor}>
                    <tspan textAnchor="middle" x="140" y="113" fontFamily={theme.fontFamily} letterSpacing="0">
                        {t('voucher.prefix.2')}
                    </tspan>
                </text>
            </g>
            <g transform="translate(330.000000, 45.000000)">
                <EventLogo
                    width="176"
                    height="76"
                    viewBox="0 0 250 130"
                    primaryColor={theme.primaryColor}
                    secondaryColor={theme.secondaryColor}
                    ternaryColor={theme.ternaryColor}
                />
            </g>
        </svg>
    )
}

function wrapText (string: string, maxLength: number = 18) {
    return string.replace(new RegExp(`(?![^\\n]{1,${maxLength}}$)([^\\n]{1,${maxLength}})\\s`, 'g'), '$1\n').split('\n')
}

export default withTheme(supportedThemes)(React.forwardRef(PromoVoucher))
