// @flow
import * as React from 'react'
import cn from 'classnames'
import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { Currency, Percent } from 'i18n/NumberFormatter'
import { Store } from 'Store.js'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

function RegisterIncentives (props) {
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    const incentives = state.campaign.incentives.manufacturer

    return (
        <div className={styles.incentivesContainer}>
            <div
                className={styles.registerIncentives}
                style={{
                    '--line-color': props.theme.lineColor,
                    '--primary-font': props.theme.primaryFont,
                    '--secondary-font': props.theme.secondaryFont,
                }}
            >
                <div className={cn('slideIn', styles.incentives)}>
                    <span>SPECIAL OFFER</span>
                    <p>
                        <Trans t={props.t} i18nKey="specialOffer" values={{
                            voucher: voucher.name,
                            type: voucher.type,
                            value: voucher.value > 0 ? currencyFormatter.format(voucher.value) : 0
                        }}>
                            Buy one of our new or pre-owned vehicles or sell us yours and receive a <strong>{currencyFormatter.format(voucher.value)} {voucher.name}</strong>.
                        </Trans>
                    </p>
                </div>

                <div className={cn('slideIn', styles.incentives)}>
                    <span>GREAT PRICES</span>
                    <p>
                        <strong>Great prices</strong> on a large selection of new or
                        pre-owned vehicles at our dealership.
                    </p>
                </div>

                <div className={cn('slideIn', styles.incentives)}>
                    <span>RATES</span>
                    <p>
                        Ask us about our rates <strong>as low a {Percent(state.locale).format((Number.parseFloat(incentives.interestRate).toPrecision(3) / 100) || 0)}</strong>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(withTranslation(transDomain)((RegisterIncentives)))
