
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsc0e93ab68b3bbeeeecf972c58f05dcbeen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'c0e93ab68b3bbeeeecf972c58f05dcbe', flattenObject(nsc0e93ab68b3bbeeeecf972c58f05dcbeen_US), true, true);
        
            import nsc0e93ab68b3bbeeeecf972c58f05dcbeen from './en.yaml'
            i18n.addResourceBundle('en', 'c0e93ab68b3bbeeeecf972c58f05dcbe', flattenObject(nsc0e93ab68b3bbeeeecf972c58f05dcbeen), true, true);
        
        export default 'c0e93ab68b3bbeeeecf972c58f05dcbe';
    