// @flow
import React from 'react'

import cn from 'classnames'
import { withTranslation } from 'react-i18next'
import Countdown from 'components/Countdown'
import AbsoluteTime from 'components/AbsoluteTime'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'
import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

type Props = {
    date: Date,
    dealershipName: string,
};

function TopBar (props: Props) {
    const { t } = props

    return (
        <div
            className={styles.topBar}
            style={{
                '--primary-font': props.theme.primaryFont,
                backgroundColor: props.theme.background
            }}
        >
            <div className={styles.container}>
                {props.theme.showLogo &&
                    <div
                        className={cn(styles.logo, styles[props.theme.logoContainerCustomClassName])}
                        style={{ backgroundColor: props.theme.alternateBackground }}>
                        <img className={cn(styles[props.theme.logoImgCustomClassName])} src={props.theme.logo} alt=""/>

                        <span className={styles.dealerName} style={{
                            color: props.theme.dealerNameColor,
                        }}>
                            {props.dealershipName}
                        </span>
                    </div>
                }

                <div className={styles.expiryText} style={{ color: props.theme.eventExpireColor }}>

                    <span>{t('event_expiry')}&nbsp; <AbsoluteTime dateFormat='long' date={props.date} /></span>

                    <div className={styles.expiryTextRight} >
                        <Countdown date={props.date} />
                    </div>
                </div>
            </div>
        </div>
    )
}

TopBar.defaultProps = {
    date: new Date()
}

export default withTheme(supportedThemes)(withTranslation(transDomain)(TopBar))
