// @flow

const VIDEOS = {
    base: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/base.mp4',
    ford: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/ford.mp4',
    honda: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/honda.mp4',
    hyundai: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/hyundai.mp4',
    kia: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/kia.mp4',
    nissan: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/nissan.mp4',
    toyota: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/reveal-the-deal-2020-1/toyota.mp4'
}

export function makeCampaign (style: string) {
    return {
        videos: {
            landingPage: VIDEOS[style] || VIDEOS.base
        },
        extraAssistanceRequest: {
            options: [
                'Video chat from a sales representative',
                'Test drive from home',
                'Virtual walk around of your vehicle of interest'
            ]
        },
        usedModels: [
            {
                'make': 'Pre-Owned',
                'name': 'Car/Sedan',
                'year': null,
                'trim': null,
                'segment': 'Car/Sedan',
                'startingPrice': null
            },
            {
                'make': 'Pre-Owned',
                'name': 'SUV/Crossover',
                'year': null,
                'trim': null,
                'segment': 'SUV/Crossover',
                'startingPrice': null
            },
            {
                'make': 'Pre-Owned',
                'name': 'Performance',
                'year': null,
                'trim': null,
                'segment': 'Performance',
                'startingPrice': null
            },
            {
                'make': 'Pre-Owned',
                'name': 'Truck',
                'year': null,
                'trim': null,
                'segment': 'Truck',
                'startingPrice': null
            },
            {
                'make': 'Pre-Owned',
                'name': 'Van',
                'year': null,
                'trim': null,
                'segment': 'Van',
                'startingPrice': null
            },
            {
                'make': 'Pre-Owned',
                'name': 'Electric/Hybrid',
                'year': null,
                'trim': null,
                'segment': 'Electric/Hybrid',
                'startingPrice': null
            }
        ]
    }
}
