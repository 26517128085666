import * as React from 'react'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes'

const EventLogo = React.forwardRef(function (props, ref) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={props.viewBox} height={props.height} width={props.width}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill={props.theme.secondaryColor}
                    d="m182.84 48.537-8.97 17.97-2.733-17.97h-9.93l6.463 27.314-2.874 16.425h9.561l2.669-15.414 16.477-28.325zM207.452 56.137c.109 0 .219.002.33.007 2.763.106 4.15 2.145 4.242 6.22 0 1.068-.116 2.617-.347 4.602l-1.027 7.243c-.55 3.802-1.52 6.563-2.883 8.206-1.336 1.61-2.967 2.377-5.081 2.283-2.845-.123-4.273-2.046-4.364-5.894-.057-1.06.031-2.56.26-4.453l1.027-7.19c.553-3.886 1.532-6.765 2.91-8.556 1.277-1.658 2.892-2.468 4.933-2.468m1.004-8.187c-3.134-.069-6.024.654-8.54 2.123-2.513 1.467-4.639 3.734-6.32 6.733-1.665 2.974-2.8 6.429-3.373 10.267l-1.089 7.114a24.964 24.964 0 0 0-.18 4.55c.2 4.348 1.456 7.802 3.729 10.266 2.286 2.48 5.414 3.779 9.295 3.86.14.003.277.004.415.004 4.658 0 8.58-1.658 11.659-4.93 3.139-3.34 5.182-8.047 6.073-13.993l.97-6.547a28.54 28.54 0 0 0 .27-4.756c-.162-4.461-1.373-8.036-3.601-10.627-2.25-2.616-5.381-3.983-9.308-4.064M247.672 48.537l-5.033 29.816c-.318 2.343-.971 4.023-1.943 4.995-.964.963-2.276 1.404-4.03 1.35-2.453-.088-3.596-1.281-3.597-3.73l.112-2.465 5.029-29.966h-9.47l-5.065 29.796c-.503 4.368.355 7.903 2.553 10.507 2.2 2.609 5.466 3.971 9.708 4.053.135.002.269.004.402.004 4.315 0 7.87-1.26 10.572-3.747 2.775-2.555 4.497-6.152 5.115-10.675l5.088-29.938h-9.441ZM39.147 28.456l-4.61 26.508-6.997-26.508h-9.057l-7.57 43.74h9.441l4.61-26.477 6.968 26.476h9.027l7.6-43.74zM46.496 72.195h24.85l1.439-8.165h-15.44l1.792-10.326h13.03l1.41-7.96H60.516l1.586-9.094h15.35l1.438-8.194H54.066zM115.01 28.456l-7.302 24.105-.582-24.105h-7.953l-8.111 23.989.29-23.99h-9.295l.751 43.74h9.333l7.925-22.862.802 22.862h9.302l14.36-43.74z"
                />
                <path
                    fill={props.theme.primaryColor}
                    d="M78.889 92.6h20.054l2.006-13.073H.102zM172.284 28.584c-.714 4.272-2.175 8.637-4.373 13.073h100.387l-72.383-13.073h-23.63ZM152.71 51.125c7.504-9.155 11.691-17.878 12.446-25.93.648-7.11-.966-13.09-4.796-17.773-3.87-4.732-9.31-7.22-16.179-7.395-7.83-.176-14.453 2.486-19.695 7.9-3.515 3.63-6.017 8.062-7.536 13.197h14.86c.718-1.576 1.571-2.98 2.592-4.155 2.387-2.748 5.365-4.033 9.101-3.932 2.001.094 3.556.817 4.75 2.211 1.277 1.49 2.046 3.585 2.283 6.19.293 4.035-.413 7.92-2.098 11.55-1.753 3.772-4.618 7.953-8.504 12.417l-31.442 34.068-2.04 13.128h52.521l2.078-13.074h-33.593L148.43 56.19l4.28-5.064Z"
                />
                <path
                    fill={props.theme.secondaryColor}
                    d="M170.993 109.205h-5.528l-.834 4.806h6.55l-.536 3.038h-10.11l3.137-18.13h10.098l-.535 3.051h-6.512l-.747 4.284h5.54zM184.499 112.031l5.005-13.111h4.072l-7.907 18.13h-3.723l-2.204-18.13h3.872zM207.878 109.205h-5.529l-.834 4.806h6.55l-.536 3.038h-10.11l3.137-18.13h10.098l-.535 3.051h-6.512l-.747 4.284h5.54zM227.098 117.05h-3.487l-3.163-12.016-2.091 12.015h-3.574l3.138-18.13h3.499l3.175 12.029 2.092-12.028h3.56zM249.17 101.97h-4.346l-2.615 15.08h-3.586l2.615-15.08h-4.284l.536-3.05h12.215z"
                />
            </g>
        </svg>
    )
})

EventLogo.defaultProps = {
    x: 0,
    y: 0,
    width: '269',
    height: '145'
}

export default withTheme(themes)(EventLogo)
