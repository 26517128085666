// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import cn from 'classnames'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import { Store } from 'Store'
import TermsAndConditions from 'components/TermsAndConditions'

type ImagePath = string;

type Props = {
    theme: {
        background: string,
        color: string,
        logo: ImagePath,
    }
}

function Footer (props: Props) {
    const { state } = React.useContext(Store)
    const { t } = props

    const clientAddress = React.useMemo(() => {
        return state.campaign.client.address ? [
            [state.campaign.client.address.line1, state.campaign.client.address.line2],
            state.campaign.client.address.city,
            [state.campaign.client.address.province, state.campaign.client.address.postalCode]
        ].filter(Boolean).map(i => Array.isArray(i) ? i.filter(Boolean).join(' ') : i).join(', ') : null
    }, [state.campaign.client.address])

    return (
        <div className={styles.container} style={{
            backgroundColor: props.theme.background,
            '--primary-font': props.theme.primaryFont,
        }}>
            <div className={styles.innerContainer}>
                <img
                    className={cn(styles.logo, styles[props.theme.logoClassName])}
                    style={props.theme.logoStyle}
                    src={props.theme.logo}
                    alt=''
                />

                <p className={styles.dealershipInfo} style={{ '--dealership-color': props.theme.dealershipColor }}>
                    {clientAddress && `${state.campaign.client.name} | ${clientAddress}`}
                </p>

                <p className={styles.disclaimer} style={{
                    color: props.theme.disclaimerColor
                }}>
                    {t('disclaimer')}
                </p>

                {state.campaign.termsAndConditions && (
                    <TermsAndConditions termsAndConditions={state.campaign.termsAndConditions}/>
                )}

                {props.theme.customLogo &&
                    <>
                        <div
                            className={styles.logo}
                            style={{
                                backgroundImage: `url(${props.theme.customLogo})`
                            }}
                            alt=''
                        />
                        <br />
                    </>
                }
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(withTranslation(transDomain)(Footer))
